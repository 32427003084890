import * as React from 'react';
import './navBar.scss';
import { Tooltip } from './tooltip';
import { Link, NavLink } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { ApplicationState } from '../store';

export const Nav = () => {
    const user = useSelector((state: ApplicationState) => state.userState.user);

    return (
        <>
            <NavLink exact to='/tasks' activeClassName='active'>Dashboard</NavLink>
            <NavLink to='/store' activeClassName='active'>Products</NavLink>
            {(user?.apiKey && user?.role === 'Admin') && <NavLink to='/apireference' activeClassName='active'>API Reference</NavLink>}
        </>
    );
}

export class PublicNav extends React.PureComponent {
    render() {
        return (
            <>
                <a href='https://www.olasio.com'>Home</a>
                <NavLink to='/public/store' activeClassName='active'>Products</NavLink>
            </>
        );
    }
}
